export default class RentOperational {
    constructor() {
        this.id = "";
        this.identification = "";
        this.number = 0;
        this.statusRental = 1;
        this.customer = { id: "", content: "" };
        this.contact = { id: "", content: "" };
        this.periodRent = {};
        this.observationRental = "";
        this.observationServiceOrder = "";
        this.dateAccepted = "";
        this.registeredDate = "";
        this.registeredUser = "";
        this.periodRent = {};
    }
    update(data) {
        this.id = data.id;
        this.number = data.number;
        this.identification = data.identification;
        this.number = data.number;
        this.statusRental = data.statusRental;

        if (data.collaborator)
            this.collaborator = { id: data.collaborator.id, content: data.collaborator.person.companyName + " (" + data.collaborator.person.tradeName + ")" };

        this.customer = { id: data.customerSupplier.id, content: data.customerSupplier.person.companyName + " (" + data.customerSupplier.person.tradeName + ")" };

        if (data.contact)
            this.contact = { id: data.contact.id, content: data.contact.name };

        this.observationRental = data.observationRental;
        this.observationServiceOrder = data.observationServiceOrder;

        this.dateAccepted = data.dateAccepted;
        this.registeredDate = data.registeredDate;
        this.registeredUser = data.registeredUser;
        this.periodRent = data.periodRent;
    }
}